<template>
  <div>
    <XyzTransition xyz="fade">
      <div class="xieyi" v-if="$store.state.xieyiShow">
        <div class="head">
          <div
            class="back"
            @click="back"
            :style="{
              background: `url('${require('@assets/img/back.png')}') no-repeat`
            }"
          ></div>
          <span>{{ $store.state.xieyi.title }}</span>
        </div>

        <div class="content">
          <div class="title">{{ $store.state.xieyi.littleTitle }}</div>
          <div
            v-for="(item, i) in $store.state.xieyi.text"
            :key="i"
            class="t-content"
            :class="{
              videocontent: $store.state.xieyi && $store.state.xieyi.title === '新手教程'
            }"
          >
            <div
              :class="{
                us:
                  ($store.state.xieyi &&
                    $store.state.xieyi.title === '关于我们' &&
                    (i == 0 || i == 1)) ||
                  ($store.state.xieyi && $store.state.xieyi.title === '新手教程')
              }"
            >
              {{ item.title }}
            </div>
            <div v-if="typeof item.content === 'string'">{{ item.content }}</div>
            <div v-else v-for="(f, ff) in item.content" :key="ff">{{ f }}</div>
            <div class="videobox" v-if="item.video">
              <video :src="item.video" controls></video>
            </div>
          </div>
        </div>
      </div>
    </XyzTransition>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    back() {
      this.$store.commit('setxieyi', {
        show: false,
        data: 2
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.xieyi {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;

  .head {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 75px;
    background: #000;

    .back {
      position: absolute;
      top: calc(50% - 12px);
      left: 28px;
      width: 24px;
      height: 24px;
      background-size: $bs !important;
    }

    span {
      color: #eeeeee;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }

  .content {
    padding: 20px 73px;
    height: calc(100% - 75px - 40px);
    background: #131617;
    color: #9e9e9e;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 2px;
      height: 10px;
    }
    /* 设置滚动条的轨道 */
    &::-webkit-scrollbar-track {
      background: #0c2033;
    }
    /* 设置滚动条里面的小方块 */
    &::-webkit-scrollbar-thumb {
      background: rgba($color: #b3b9bc, $alpha: 0.3);
      border-radius: 10px;
    }

    .title {
      text-align: center;
      font-size: 18px;
    }

    .t-content {
      font-size: 14px;
      line-height: 26px;
      margin-top: 35px;

      .us {
        color: #fff;
      }
    }

    .videocontent {
      width: calc(1920px * 0.2);
      margin: 0 auto 0 auto;
    }

    .videobox {
      width: calc(1920px * 0.2);
      margin: 10px auto 0 auto;

      video {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// 移动端 竖屏
@media screen and (max-width: 767px) and (max-height: 999px) and (orientation: portrait) {
  .xieyi {
    .content {
      padding: 20px 28px;

      .t-content {
        margin-top: 30px;
      }

      .videocontent {
        width: calc(1920px * 0.16);
        margin: 0 auto 0 auto;
      }

      .videobox {
        width: calc(1920px * 0.16);
        margin: 10px auto 0 auto;

        video {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
