<template>
  <div>
    <div class="login-page flex1" v-show="step === 1">
      <canvas id="canvas" class="canvas" v-show="!heng"></canvas>
      <canvas id="canvas2" class="canvas" v-show="heng"></canvas>

      <div class="logo_intro">
        <img class="logo" src="@assets/img/logo.png" alt="" />
        <img class="title" src="@assets/img/title.png" alt="" />
        <div class="intro">打造出版行业的首个元宇宙</div>
      </div>

      <div class="btns" v-show="!heng">
        <div class="btn" @click="step++">登录图壤</div>
        <div class="btn btn1" @click="guestLogin">快速进入</div>
      </div>

      <div class="btns" v-show="heng">
        <div class="btn btn1" @click="guestLogin">快速进入</div>
        <div class="btn" @click="step++">登录图壤</div>
      </div>
    </div>

    <div class="login-page flex2 login-page2" v-show="step === 2">
      <div
        class="back"
        v-show="view"
        @click="step--"
        :style="{
          background: `url('${require('@assets/img/back.png')}') no-repeat`
        }"
      ></div>

      <div class="left">
        <img class="title titles" src="@assets/img/title.png" alt="" />
        <div class="ftitle">手机号登录</div>
      </div>

      <div class="right">
        <div class="phone">
          <span class="p-name">手机号</span>
          <div class="p-form">
            <div class="p-left">+86</div>
            <input
              class="p-input"
              placeholder="输入手机号"
              type="number"
              v-model="loginData.phone"
              @input="solveMaxlength(1)"
              @focus="txtChange(1)"
              @keyup.enter="login"
              @blur="ok"
            />
            <img
              class="clear"
              src="@assets/img/clear.png"
              alt=""
              v-show="phoneFocus"
              @click="clearTxt(1)"
            />
          </div>
          <XyzTransition xyz="fade">
            <div class="tip" v-show="loginData.phoneTxt">{{ loginData.phoneTxt }}</div>
          </XyzTransition>
        </div>

        <div class="phone code">
          <span class="p-name">验证码</span>
          <div class="p-form">
            <input
              type="number"
              v-model="loginData.code"
              placeholder="输入验证码"
              @focus="txtChange(0)"
              @blur="ok"
              class="p-input c-input"
              @keyup.enter="login"
              @input="solveMaxlength(0)"
            />
            <span
              class="send-code"
              @click="getCode"
              :class="{
                'send-codes': disable
              }"
            >
              {{ disable ? time + 's后再次发送' : '发送验证码' }}</span
            >
          </div>
          <XyzTransition xyz="fade">
            <div class="tip" v-show="loginData.codeTxt">{{ loginData.codeTxt }}</div>
          </XyzTransition>
        </div>

        <div class="btns btn-login">
          <div class="btn" @click="login">登录</div>
        </div>
      </div>
    </div>

    <div
      class="agreement"
      :class="{
        agreement2: step === 2
      }"
      v-show="view"
    >
      <div
        class="check"
        :style="{
          background: `url('${
            $store.state.agree
              ? require('@assets/img/check.png')
              : require('@assets/img/nocheck.png')
          }') no-repeat`
        }"
        @click="$store.commit('changeAgree', !$store.state.agree)"
      ></div>
      <div class="read">
        已阅读并同意<span class="agreement-text" @click="xieyiOpen(1)">《用户协议》</span>和<span
          class="agreement-text"
          @click="xieyiOpen(0)"
          >《图壤隐私条款》</span
        >
      </div>
    </div>

    <xieyi />
  </div>
</template>

<script>
import { sendCode, login, userInfo, registerPsd } from '../assets/js/api'
import xieyi from '@/components/pageFunc/xieyi.vue'

export default {
  components: {
    xieyi
  },
  mounted() {
    if (
      navigator.userAgent.indexOf('Windows') == -1 &&
      (window.orientation == 180 || window.orientation == 0)
    ) {
      this.star('canvas')
      this.star1 = true
      this.heng = false
    }

    if (
      navigator.userAgent.indexOf('Windows') == -1 &&
      (window.orientation == 90 || window.orientation == -90)
    ) {
      this.star('canvas2')
      this.star2 = true
      this.heng = true
    }

    if (!this.$lyh.isMobile()) {
      this.star('canvas')
    }

    // window.addEventListener('resize', () => {
    //   const newScreenHeight = window.innerHeight

    //   if (navigator.userAgent.indexOf('iPhone') > -1 || navigator.userAgent.indexOf('iPad') > -1) {
    //   } else {
    //     if (newScreenHeight < window.screen.height / 2) {
    //       // 键盘弹出
    //       // alert('键盘弹出')
    //       this.view = false
    //     } else {
    //       // 键盘关闭
    //       // alert('键盘关闭')
    //       this.view = true
    //     }
    //   }
    // })

    window.addEventListener(
      'orientationchange',
      () => {
        if (
          navigator.userAgent.indexOf('Windows') == -1 &&
          (window.orientation == 90 || window.orientation == -90)
        ) {
          if (!this.star2) {
            this.star('canvas2')
            this.star2 = true
          }
          this.heng = true
        }

        if (
          navigator.userAgent.indexOf('Windows') == -1 &&
          (window.orientation == 180 || window.orientation == 0)
        ) {
          if (!this.star1) {
            this.star('canvas')
            this.star1 = true
          }
          this.heng = false
        }
      },
      false
    )
  },
  data() {
    return {
      step: 1,
      loginData: {
        phone: '',
        code: '',
        phoneTxt: '',
        codeTxt: ''
      },
      phoneFocus: false, // 手机号清空显示
      codeFocus: false, // 验证码清空显示
      timer: null, // 防抖
      timerval: null, // 60秒轮询计时器
      enter: true, // 是否点击登录按钮过
      time: 0, // 默认时长
      disable: false, // 按钮状态,
      view: true, // 视距
      heng: false,
      star1: false,
      star2: false
    }
  },
  methods: {
    // input type 为number 时 解决maxlength 失效问题
    solveMaxlength(v) {
      if (this.loginData.phone.length > 11) {
        this.loginData.phone = this.loginData.phone.slice(0, 11)
      }

      if (this.loginData.code.length > 4) {
        this.loginData.code = this.loginData.code.slice(0, 4)
      }

      if (v) {
        this.phoneFocus = Boolean(this.loginData.phone)
      } else {
        this.codeFocus = Boolean(this.loginData.code)
      }
    },
    random(min, max) {
      if (arguments.length < 2) {
        max = min
        min = 0
      }

      if (min > max) {
        var hold = max
        max = min
        min = hold
      }

      //返回min和max之间的一个随机值
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    maxOrbit(x, y) {
      var max = Math.max(x, y),
        diameter = Math.round(Math.sqrt(max * max + max * max))
      //星星移动范围，值越大范围越小，
      return diameter / 2
    },
    star(val) {
      let that = this
      var canvas = document.getElementById(val),
        ctx = canvas.getContext('2d'),
        hue = 217, //色调色彩
        stars = [], //保存所有星星
        count = 0, //用于计算星星
        maxStars = 1300 //星星数量

      if (navigator.userAgent.indexOf('iPhone') > -1 || navigator.userAgent.indexOf('iPad') > -1) {
        var w = (canvas.width = val === 'canvas' ? window.screen.width : window.screen.height)
        var h = (canvas.height = val === 'canvas' ? window.screen.height : window.screen.width)
      } else {
        var w = (canvas.width = window.screen.width)
        var h = (canvas.height = window.screen.height)
      }

      //canvas2是用来创建星星的源图像，即母版，
      //根据星星自身属性的大小来设置
      var canvas2 = document.createElement('canvas'),
        ctx2 = canvas2.getContext('2d')
      canvas2.width = 100
      canvas2.height = 100
      //创建径向渐变，从坐标(half，half)半径为0的圆开始，
      //到坐标为(half,half)半径为half的圆结束
      var half = canvas2.width / 2,
        gradient2 = ctx2.createRadialGradient(half, half, 0, half, half, half)
      gradient2.addColorStop(0.025, '#CCC')
      //hsl是另一种颜色的表示方式，
      //h->hue,代表色调色彩，0为red，120为green，240为blue
      //s->saturation，代表饱和度，0%-100%
      //l->lightness，代表亮度，0%为black，100%位white
      gradient2.addColorStop(0.1, 'hsl(' + hue + ', 61%, 33%)')
      gradient2.addColorStop(0.25, 'hsl(' + hue + ', 64%, 6%)')
      gradient2.addColorStop(1, 'transparent')

      ctx2.fillStyle = gradient2
      ctx2.beginPath()
      ctx2.arc(half, half, half, 0, Math.PI * 2)
      ctx2.fill()

      var Star = function() {
        //星星移动的半径
        this.orbitRadius = that.random(that.maxOrbit(w, h))
        //星星大小，半径越小，星星也越小，即外面的星星会比较大
        this.radius = that.random(60, this.orbitRadius) / 14
        //所有星星都是以屏幕的中心为圆心
        this.orbitX = w / 2
        this.orbitY = h / 2
        //星星在旋转圆圈位置的角度,每次增加speed值的角度
        //利用正弦余弦算出真正的x、y位置
        this.timePassed = that.random(0, maxStars)
        //星星移动速度
        this.speed = that.random(this.orbitRadius) / 700000
        //星星图像的透明度
        this.alpha = that.random(2, 10) / 10

        count++
        stars[count] = this
      }

      Star.prototype.draw = function() {
        //星星围绕在以屏幕中心为圆心，半径为orbitRadius的圆旋转
        var x = Math.sin(this.timePassed) * this.orbitRadius + this.orbitX,
          y = Math.cos(this.timePassed) * this.orbitRadius + this.orbitY,
          twinkle = that.random(100)

        //星星每次移动会有1/10的几率变亮或者变暗
        if (twinkle === 1 && this.alpha > 0) {
          //透明度降低，变暗
          this.alpha -= 0.05
        } else if (twinkle === 2 && this.alpha < 1) {
          //透明度升高，变亮
          this.alpha += 0.05
        }

        ctx.globalAlpha = this.alpha
        //使用canvas2作为源图像来创建星星，
        //位置在x - this.radius / 2, y - this.radius / 2
        //大小为 this.radius
        ctx.drawImage(canvas2, x - this.radius / 2, y - this.radius / 2, this.radius, this.radius)
        //没旋转一次，角度就会增加
        this.timePassed += this.speed
      }

      //初始化所有星星
      for (var i = 0; i < maxStars; i++) {
        new Star()
      }

      function animation() {
        //以新图像覆盖已有图像的方式进行绘制背景颜色
        ctx.globalCompositeOperation = 'source-over'
        ctx.globalAlpha = 0.5 //尾巴
        ctx.fillStyle = 'hsla(' + hue + ', 64%, 6%, 2)'
        ctx.fillRect(0, 0, w, h)

        //源图像和目标图像同时显示，重叠部分叠加颜色效果
        ctx.globalCompositeOperation = 'lighter'
        for (var i = 1, l = stars.length; i < l; i++) {
          stars[i].draw()
        }

        //调用该方法执行动画，并且在重绘的时候调用指定的函数来更新动画
        //回调的次数通常是每秒60次
        window.requestAnimationFrame(animation)
      }

      animation()
    },
    async getCode() {
      if (!this.loginData.phone) {
        this.loginData.phoneTxt = '请输入手机号！'
        setTimeout(() => {
          this.loginData.phoneTxt = ''
        }, 3000)
        return
      }

      if (!this.$lyh.phoneValidate(this.loginData.phone)) {
        this.loginData.phoneTxt = '请输入正确的手机号码！'
        setTimeout(() => {
          this.loginData.phoneTxt = ''
        }, 3000)
        return
      }

      if (this.time > 0) {
        return
      }

      this.disable = true
      this.time = 60
      this.timerval = setInterval(async () => {
        this.time--
        if (this.time == 0) {
          clearInterval(this.timerval)
          this.disable = false
        }
      }, 1000)

      try {
        const res = await sendCode({ mobile: this.loginData.phone, type: 'awifi' })
        if (res.code != 0) {
          if (res.data?.body?.message) {
            this.$message.error('短信发送失败: ' + res.data.body.message + ' 请稍后再试')
          }
        } else {
          this.$message.success('发送成功')
        }
      } catch (error) {
        this.$message.error('短信发送失败: ' + error)
      }
    },
    clearTxt(v) {
      if (v) {
        this.loginData.phone = ''
        this.phoneFocus = false
      } else {
        this.loginData.code = ''
        this.codeFocus = false
      }
    },
    txtChange(v) {
      if (v) {
        this.phoneFocus = Boolean(this.loginData.phone)
      } else {
        this.codeFocus = Boolean(this.loginData.code)
      }

      this.view = false
    },
    ok() {
      this.view = true
    },
    async login() {
      if (!this.loginData.phone) {
        this.loginData.phoneTxt = '请输入手机号！'
        setTimeout(() => {
          this.loginData.phoneTxt = ''
        }, 3000)
        return
      }

      if (!this.loginData.code) {
        this.loginData.codeTxt = '请先获取验证码！'
        setTimeout(() => {
          this.loginData.codeTxt = ''
        }, 3000)
        return
      }

      if (!this.$lyh.phoneValidate(this.loginData.phone)) {
        this.loginData.phoneTxt = '请输入正确的手机号码！'
        setTimeout(() => {
          this.loginData.phoneTxt = ''
        }, 3000)
        return
      }

      if (!this.$lyh.codeValidate(this.loginData.code)) {
        this.loginData.codeTxt = '请输入正确的短信验证码！'
        setTimeout(() => {
          this.loginData.codeTxt = ''
        }, 3000)
        return
      }

      if (!this.$store.state.agree) {
        this.$store.commit('changeOpenModalShowInfo', {
          title: '请先阅读并同意《用户协议》和《图壤隐私条款》',
          ok: '同意',
          cancel: '取消',
          function: 'agree'
        })
        this.$store.commit('changeOpenModalShow', true)
        return
      }

      let instance = this.$loading({
        fullscreen: true,
        attach: 'body',
        preventScrollThrough: false,
        showOverlay: true,
        text: '请求中..'
      })

      // 30秒自动关闭
      const timer = setTimeout(() => {
        clearTimeout(timer)
        instance.hide()
        instance = null
        console.log('超时,自动关闭窗口')
      }, 30000)

      try {
        const result = await login({
          acount: this.loginData.phone,
          code: this.loginData.code
        })
        if (result.code == 1) {
          if (result.msg) {
            this.$message.error(result.msg)
          } else {
            throw 'no msg'
          }
        } else if (result.code == 0) {
          if (result.data) {
            try {
              await this.$store.commit('setToken', result.data)
              try {
                const res = await userInfo()
                if (res.code == 0) {
                  this.$router.replace('index')
                } else throw 'getInfo code not 0'
              } catch (error) {
                throw 'getInfo error' + error
              }
            } catch (error) {
              throw 'saveToken error' + error
            }
          } else {
            throw 'getToken no data'
          }
        } else throw 'getToken code is not 0/1'
      } catch (error) {
        this.$message.error('登录失败: ' + error)
      } finally {
        if (instance) {
          instance.hide()
          clearTimeout(timer)
        }
      }
    },
    async guestLogin() {
      await this.$store.commit(
        'setToken',
        JSON.stringify({
          type: 'guest',
          id: this.$lyh.RandomNumBoth(40000, 60000),
          name: '游客' + this.$lyh.generateRandomString(4)
        })
      )
      this.$router.replace('index')
    },
    xieyiOpen(v) {
      this.$store.commit('setxieyi', {
        show: true,
        data: v
      })
    }
  }
}
</script>

<style scoped lang="scss">
.canvas {
  position: fixed;
  z-index: -2;
}

@import '../assets/css/login.scss';
</style>
