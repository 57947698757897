var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('XyzTransition',{attrs:{"xyz":"fade"}},[(_vm.$store.state.xieyiShow)?_c('div',{staticClass:"xieyi"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"back",style:({
            background: ("url('" + (require('@assets/img/back.png')) + "') no-repeat")
          }),on:{"click":_vm.back}}),_c('span',[_vm._v(_vm._s(_vm.$store.state.xieyi.title))])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$store.state.xieyi.littleTitle))]),_vm._l((_vm.$store.state.xieyi.text),function(item,i){return _c('div',{key:i,staticClass:"t-content",class:{
            videocontent: _vm.$store.state.xieyi && _vm.$store.state.xieyi.title === '新手教程'
          }},[_c('div',{class:{
              us:
                (_vm.$store.state.xieyi &&
                  _vm.$store.state.xieyi.title === '关于我们' &&
                  (i == 0 || i == 1)) ||
                (_vm.$store.state.xieyi && _vm.$store.state.xieyi.title === '新手教程')
            }},[_vm._v(" "+_vm._s(item.title)+" ")]),(typeof item.content === 'string')?_c('div',[_vm._v(_vm._s(item.content))]):_vm._l((item.content),function(f,ff){return _c('div',{key:ff},[_vm._v(_vm._s(f))])}),(item.video)?_c('div',{staticClass:"videobox"},[_c('video',{attrs:{"src":item.video,"controls":""}})]):_vm._e()],2)})],2)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }